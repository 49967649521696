<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row>
      <div class="text-center">
        <base-material-snackbar
          v-model="updateOk"
          :type="colorSnackbarSuccess"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> L'utilisateur a bien été modifié.</span>
        </base-material-snackbar>

        <base-material-snackbar
          v-model="nouveauMotDePasseOK"
          :type="colorSnackbarSuccess"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> L'utilisateur a bien été modifié.</span>
        </base-material-snackbar>

        <base-material-snackbar
          v-model="KOupdate"
          :type="colorSnackbarError"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> Erreur lors de la modification de l'utilisateur</span>
        </base-material-snackbar>
      </div>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <base-material-card
              title="Informations Utilisateur"
            >
              <template>
                <div class="text-h3 font-weight-light">
                  <v-row>
                    <v-col md="4">
                      <v-text-field
                        v-model="infoUser.nom"
                        label="Nom"
                        placeholder="Placeholder"
                      />
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        v-model="infoUser.prenom"
                        label="Nom"
                        placeholder="Placeholder"
                      />
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        v-model="infoUser.telephone"
                        label="Telephone"
                        placeholder="Placeholder"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="5">
                      <v-text-field
                        v-model="infoUser.mail"
                        label="Mail"
                        placeholder="Placeholder"
                      />
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        v-model="infoUser.labelStatusClient"
                        label="Statut"
                        placeholder="Statut"
                      />
                    </v-col>
                    <v-col
                      v-if="infoUser.status == 2"
                      md="3"
                    >
                      <v-text-field
                        v-model="infoUser.taux_remise"
                        label="Taux de remise (%)"
                        placeholder="Taux de remise en %"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="infoUser.status == 2">
                    <v-col md="7">
                      <v-text-field
                        v-model="infoUser.societe"
                        label="Société"
                        placeholder="Société"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="infoUser.status == 2">
                    <v-col md="5">
                      <v-text-field
                        v-model="infoUser.TVA"
                        label="N° TVA"
                        placeholder="N° TVA"
                      />
                    </v-col>
                    <v-col md="5">
                      <v-text-field
                        v-model="infoUser.numero_siret"
                        label="N° Siret"
                        placeholder="N° Siret"
                      />
                    </v-col>
                  </v-row>
                  <!-- <v-simple-table>
                <thead>
                  <tr>
                    <th class="primary--text">
                      Id
                    </th>
                    <th class="primary--text">
                      Nom
                    </th>
                    <th class="primary--text">
                      Prenom
                    </th>
                    <th class="primary--text">
                      Mail
                    </th>
                    <th class="primary--text">
                      Telephone
                    </th>
                    <th class="primary--text">
                      Status
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td> {{ infoUser.id }} </td>
                    <td> <input v-model="infoUser.nom"> </td>
                    <td> <input v-model="infoUser.prenom"> </td>
                    <td> {{ infoUser.mail }} </td>
                    <td> <input v-model="infoUser.telephone"> </td>
                    <td> {{ infoUser.labelStatusClient }} </td>
                  </tr>
                </tbody>
              </v-simple-table> -->
                </div>
              </template>
            </base-material-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <base-material-card
              title="Adresse Facturation"
            >
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="adresseFacturation.nom"
                    label="Nom"
                    placeholder="Nom"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="adresseFacturation.prenom"
                    label="Prenom"
                    placeholder="Prenom"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field
                    v-model="adresseFacturation.adresse"
                    label="Adresse"
                    placeholder="Adresse"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field
                    v-model="adresseFacturation.complement_adresse"
                    label="Cplt Adresse"
                    placeholder="Cplt Adresse"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="adresseFacturation.code_postal"
                    label="Code Postal"
                    placeholder="Code Postal"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="adresseFacturation.ville"
                    label="Ville"
                    placeholder="Ville"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="9">
                  <v-text-field
                    v-model="adresseFacturation.pays"
                    label="Pays"
                    placeholder="Pays"
                  />
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
          <v-col
            cols="6"
            md="6"
          >
            <base-material-card
              title="Adresse Livraison"
            >
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="adresseLivraison.nom"
                    label="Nom"
                    placeholder="Nom"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="adresseLivraison.prenom"
                    label="Prenom"
                    placeholder="Prenom"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field
                    v-model="adresseLivraison.adresse"
                    label="Adresse"
                    placeholder="Adresse"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field
                    v-model="adresseLivraison.complement_adresse"
                    label="Cplt Adresse"
                    placeholder="Cplt Adresse"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="adresseLivraison.code_postal"
                    label="Code Postal"
                    placeholder="Code Postal"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="adresseLivraison.ville"
                    label="Ville"
                    placeholder="Ville"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="9">
                  <v-text-field
                    v-model="adresseLivraison.pays"
                    label="Pays"
                    placeholder="Pays"
                  />
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <base-material-card
              title="Commande de l'utilisateur"
            >
              <template>
                <div class="text-h3 font-weight-light">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="primary--text">
                          Id Commande
                        </th>
                        <th class="primary--text">
                          Numero Commande
                        </th>
                        <th class="primary--text">
                          Statut
                        </th>
                        <th class="primary--text">
                          Montant
                        </th>
                        <th class="primary--text" />
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="commande in commandes"
                        :key="commande.id"
                      >
                        <td> {{ commande.id_commande }} </td>
                        <td> {{ commande.numero }} </td>
                        <td> {{ commande.labelStatut }} </td>
                        <td> {{ commande.montant }} </td>
                        <td>
                          <v-btn
                            small
                            color="edit"
                            @click="GoDetailsCommande(commande)"
                          >
                            <v-icon left>
                              mdi-pencil
                            </v-icon>
                            Editer
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </template>
            </base-material-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card-text>
          <v-row>
            <v-btn
              color="edit"
              class="mr-2 mt-4"
              @click="ModifierUser(infoUser)"
            >
              <v-icon left>
                mdi-account-details
              </v-icon>
              Enregistrer les informations
            </v-btn>
            <v-btn
              color="secondary"
              class="mr-2 mt-4"
              @click="ChangerStatut()"
            >
              <v-icon left>
                mdi-state-machine
              </v-icon>
              Modifier Statut
            </v-btn>
            <!-- <v-btn
                color="success"
                class="mr-2 mt-4"
                @click="ExporterCommande(infoUser)"
              >
              <v-icon left>
                mdi-pdf-box
              </v-icon>
                Exporter PDF
              </v-btn> -->
            <v-btn
              color="info"
              class="mr-2 mt-4"
              @click="nouveauMotDePasse()"
            >
              <v-icon left>
                mdi-lock-reset
              </v-icon>
              Reinitialiser mot de passe
            </v-btn>
            <v-btn
              color="error"
              class="mr-2 mt-4"
              @click="SupprimerUser(infoUser)"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Supprimer
            </v-btn>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          title="Note interne"
        >
          <v-textarea
            v-model="note"
            class="purple-input"
            label="Note"
            value="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
          <v-btn
            @click="modifierNote(note)"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Editer
          </v-btn>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import {
    mapState,
  } from 'vuex'
  import CryptoJS from 'crypto-js'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      FromCommande: false,
      note: '',
      updateOk: false,
      KOupdate: false,
      nouveauMotDePasseOK: false,
      adresseFacturation: {},
      adresseLivraison: {},
      colorSnackbarSuccess: 'success',
      colorSnackbarError: 'error',
      infoUser: '',
      statutItem: 'Publié',
      commandes: [],
      direction: 'top right',
      // infoCommandeBDD: [],
      // statut_commande : 1 en attente de paiement, 2 payé, 3 annulé, 4 supprimé , 5 remboursé
      status: [{
        statutId: 1,
        statutLabel: '1 en attente de paiements',
      }, {
        statutId: 2,
        statutLabel: 'Payé',
      }, {
        statutId: 3,
        statutLabel: 'Annulé',
      }, {
        statutId: 4,
        statutLabel: 'Supprimé',
      }, {
        statutId: 5,
        statutLabel: 'Remboursé',
      },
      ],
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
      parsedDirection () {
        return this.direction.split(' ')
      },
    },
    mounted () {
      if (typeof this.$route.params.test !== 'undefined') {
        this.FromCommande = true
      }
    },
    created () {
      var userId
      if (typeof this.$route.params.infoUser !== 'undefined') {
        this.infoUser = this.$route.params.infoUser
        this.$localStorage.set('idUser', this.infoUser.id)
        userId = this.infoUser.id
      } else {
        // Récupération info utilisateurs
        userId = this.$localStorage.get('idUser')
        Vue.axios.get('https://api.espace-jantes.com/getOneUser', {
          params: { id_user: userId },
        })
          .then((response) => {
            response.data[0].labelStatusClient = this.GetTypeClient(response.data[0].status)
            this.infoUser = response.data[0]
          })
          .catch(error => console.log(error))
      }
      Vue.axios.get('https://api.espace-jantes.com/getOrdersByUser', {
        params: { id_user: userId },
      })
        .then((response) => {
          response.data.forEach(element => {
            element.labelStatut = this.GetStatut(element.statut)
            this.commandes.push(element)
          })
        })
        .catch(error => console.log(error))
      Vue.axios.get('https://api.espace-jantes.com/getAdresseUser', {
        params: { id_user: userId },
      })
        .then((response) => {
          response.data.forEach(element => {
            if (element.type_adresse === 1) {
              this.adresseFacturation = element
            }
            if (element.type_adresse === 2) {
              this.adresseLivraison = element
            }
          })
        })
        .catch(error => console.log(error))
    },
    methods: {
      GoDetailsCommande: function (commande) {
        this.$router.push({ name: 'CommandeDetails', params: { infoCommande: commande, infoUser: this.infoUser } })
      },
      ModifierUser: function (infoUser) {
        // this.ModifierAdresseUser(infoUser)
        console.log('infoUser', infoUser)
        const cryptedinfoUser = CryptoJS.AES.encrypt(JSON.stringify(this.infoUser), process.env.VUE_APP_SECRET_KEY).toString()

        Vue.axios.put('https://api.espace-jantes.com/updateUser', {
          params: { infoUser: cryptedinfoUser },
        })
          .then((response) => {
            console.log('respo', response)
            if (response.data.etat) {
              this.updateOk = true
              this.colorSnackbarSuccess = 'success'
            } else {
              this.KOUpdate = true
              this.colorSnackbarError = 'error'
            }
            // var self = this
            // setTimeout(function () { self.$router.go(0) }, 2000)
          })
          .catch(error => console.log(error))
      },
      // ModifierAdresseUser: function (infoUser) {
      //   console.log(this.VerifAdresse())
      //   if (this.VerifAdresse()) {
      //     Vue.axios.post('https://api.espace-jantes.com/addAdresse', {
      //       params: {
      //         id_user: infoUser.id,
      //         addr_facturation: this.adresseFacturation,
      //         addr_livraison: this.adresseLivraison,
      //       },
      //     })
      //   }
      //   // console.log(this.adresseFacturation.nom)
      // },
      // VerifAdresse: function () {
      //   var livraison = false
      //   var facturation = false
      //   if (this.adresseLivraison.nom !== undefined && this.adresseLivraison.nom !== 'undefined' &&
      //     this.adresseLivraison.prenom !== undefined && this.adresseLivraison.prenom !== 'undefined' &&
      //     this.adresseLivraison.adresse !== undefined && this.adresseLivraison.adresse !== 'undefined' &&
      //     this.adresseLivraison.code_postal !== undefined && this.adresseLivraison.code_postal !== 'undefined' &&
      //     this.adresseLivraison.ville !== undefined && this.adresseLivraison.ville !== 'undefined' &&
      //     this.adresseLivraison.pays !== undefined && this.adresseLivraison.pays !== 'undefined'
      //   ) {
      //     livraison = true
      //   }
      //   if (this.adresseFacturation.nom !== undefined && this.adresseFacturation.nom !== 'undefined' &&
      //     this.adresseFacturation.prenom !== undefined && this.adresseFacturation.prenom !== 'undefined' &&
      //     this.adresseFacturation.adresse !== undefined && this.adresseFacturation.adresse !== 'undefined' &&
      //     this.adresseFacturation.code_postal !== undefined && this.adresseFacturation.code_postal !== 'undefined' &&
      //     this.adresseFacturation.ville !== undefined && this.adresseFacturation.ville !== 'undefined' &&
      //     this.adresseFacturation.pays !== undefined && this.adresseFacturation.pays !== 'undefined'
      //   ) {
      //     facturation = true
      //   }
      //   if (facturation && livraison) {
      //     return true
      //   }
      //   return false
      // },
      GetTypeClient: function (idTypeClient) {
        var elementToReturn
        switch (idTypeClient) {
          case 1:
            elementToReturn = 'Admin'
            break
          case 2:
            elementToReturn = 'Pro'
            break
          case 3:
            elementToReturn = 'Particulier'
            break
          case 4:
            elementToReturn = 'En attente'
            break
          default:
            elementToReturn = 'Particulier'
        }
        return elementToReturn
      },
      GetStatut: function (idStatut) {
        var elementToReturn
        switch (idStatut) {
          case 1:
            elementToReturn = 'en attente de paiement'
            break
          case 2:
            elementToReturn = 'payé'
            break
          case 3:
            elementToReturn = 'traité'
            break
          case 4:
            elementToReturn = 'annulé'
            break
          case 5:
            elementToReturn = 'supprimé'
            break
          case 6:
            elementToReturn = 'remboursé'
            break
          default:
            elementToReturn = 'Error'
        }
        return elementToReturn
      },
      ExporterCommande: function () {
        console.log('ExporterCommande')
      },
      SupprimerUser: function () {
        Vue.axios.post('https://api.espace-jantes.com/deleteUser', {
          params:
            {
              id: this.infoUser.id,
            },
        })
          .then((response) => {
            this.$router.push({ path: '/users/list-users' })
          })
      },
      modifierNote: function (note) {
        console.log(note)
      },
      ChangerStatut: function () {
        if (this.infoUser.status !== 2 && this.infoUser.status !== 3 && this.infoUser.status !== 4) {
          this.KOUpdate = true
          this.colorSnackbarError = 'error'
          return false
        }
        var nouveauStatus = (this.infoUser.status === 2) ? 3 : 2
        if (this.infoUser.status === 4) {
          nouveauStatus = 2
        }
        Vue.axios.put('https://api.espace-jantes.com/statutEspacePro', {
          params:
            {
              status: nouveauStatus,
              id: this.infoUser.id,
              nom: this.infoUser.nom,
              prenom: this.infoUser.prenom,
              mail: this.infoUser.mail,
              websiteid: this.$cookie.get('website_id') === '1' ? '1' : '2',
            },
        })
          .then((response) => {
            if (response.data.etat) {
              this.updateOk = true
              this.colorSnackbarSuccess = 'success'
            } else {
              this.KOUpdate = true
              this.colorSnackbarError = 'error'
            }
            var self = this
            setTimeout(function () { self.$router.go(0) }, 2000)
          })
          .catch(error => console.log(error))
      },
      nouveauMotDePasse: function () {
        Vue.axios.put('https://api.espace-jantes.com/mdpOublie', {
          params:
            {
              mail: this.infoUser.mail,
            },
        })
          .then((response) => {
            if (response.data.etat) {
              this.nouveauMotDePasseOK = true
              this.colorSnackbarSuccess = 'success'
            }
          })
          .catch(error => console.log(error))
      },
    },
  }
</script>
<style scoped>
  input:focus {
    border: 2px solid black !important;
    border-radius : 4px;
  }
</style>
